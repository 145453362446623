import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import titreRubrique from "../assets/images/accueil/puce_titre_rubrique.gif"
import mainTitle from "../assets/images/titres/acces_EC3D.gif"
import contacts from "../assets/images/titres/nos_contacts_EC3D.gif"
import 'react-slidedown/lib/slidedown.css'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import "./contact-rosny-sur-seine.css"

const contactRosnyPage = ({data}) => (
  <Layout>
    <SEO title="Contact et accès - Rosny-sur-Seine - EC3D" />
    <div id="contact-rosny" class="rubrique">
      <div id="titre">
          <img src={titreRubrique} width="25" height="32" alt="puce titre rubrique"/>
          <span class="h1"><img src={mainTitle} width="518" height="32" alt="Rubrique Notre équipement - EC3D" /></span>
          <p class="titre-texte h2">
          </p>
      </div>
              
      <div id="contenu">

        <div class="block main-block">
          <div class="inner-block">
            <div class="h3" dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.allContentfulContact.edges[0].node.introduction.json) }}>
            </div>
            <div class="google-map">
              <iframe title="rosny-map" width="602" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3439.3968643056955!2d1.6489306720967387!3d48.993541687088545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5ea874dd209023a6!2sExpertise%20Contr%C3%B4le%203D!5e1!3m2!1sfr!2sfr!4v1581010160616!5m2!1sfr!2sfr"></iframe>
            </div>
          </div>
        </div>

        <div class="block side-block">
          <div class="inner-block">
            <div class="block-title h1"><img src={contacts} width="171" height="22" alt="Innovation EC3D" />
            </div>
            <div class="h3">
              <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.allContentfulContact.edges[0].node.contacts.json) }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default contactRosnyPage

export const query = graphql`
    query {
      allContentfulContact(filter: {title: {regex: "/Rosny/"}}) {
        edges {
          node {
            id,
            introduction {
              json
            },
            contacts{
              json
            }
          }
        }
      }
    }
  `